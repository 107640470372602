import React from 'react';

import LazyImg from '~/components/lazy-img';
import MobileCarousel from '~/components/plans/mobile-carousel';
import { widgetTypes } from '~/components/products/yotpo-product-reviews-widget';
import FullWidthSection from '~/components/static/full-width-section';
import HeroInputForm from '~/components/static/home/hero-input-form';
import SmartLawnPlanReviews from '~/components/static/reviews/smart-lawn-plan-reviews';
import LazyLoad from '~/components/lazy-load';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import { ZIPCODE_FUNNEL_FLAG } from '~/feature-flags';

import styles from '~/components/static/home/smart-lawn-plan-section/smart-lawn-plan-section.module.scss';

const SmartLawnPlanSection = ({ address, addressText }) => (
  <FullWidthSection
    backgroundColor={'var(--light-color)'}
    className={styles.smartLawnPlanSection}
    id="homepage-lawn-plan-section"
  >
    <h2 className={styles.smartLawnTitle}>Just grab a hose</h2>
    <p className={styles.smartLawnDescription}>
      Get the lush green lawn of your dreams—without all the toxic stuff. You'll
      have zero guesswork with a subscription of seasonal applications{' '}
      <strong>
        tailored to your soil, your climate, and your unique needs.
      </strong>{' '}
      Weeds? Bare spots? Pets? No problem.
    </p>
    <h3 className={styles.smartLawnSubtitle}>How it works</h3>
    <MobileStepCarousel />
    <DesktopSteps />
    <p className={styles.smartLawnDescription}>
      Plus: You'll get expert advice from our lawn advisors. And everything
      ships for free!
    </p>
    <div className={styles.experimentContainer}>
      <h2>Let's get started</h2>
      <HeroInputForm
        analyticsId="homepage-secondary-input"
        type={
          ZIPCODE_FUNNEL_FLAG
            ? AutocompleteTypes.ZIP_CODE
            : AutocompleteTypes.FULL_ADDRESS
        }
        initialValues={{ address, addressText }}
      />
    </div>
    <div className={styles.reviewsBlockContainer}>
      <div className={styles.reviewStarsContainer}>
        <LazyLoad height="2.6rem">
          <SmartLawnPlanReviews widget={widgetTypes.STARS} />{' '}
        </LazyLoad>
        {/* Since the Yotpo widget doesn't give us back an overall rating, we're hard-coding this */}
        <strong>4.3</strong> rating
      </div>
      <p className={styles.moreReviewsLinkContainer}>
        Based on over{' '}
        <a
          className={styles.moreReviewsLink}
          href="#reviews"
          data-heap-id="see-more-reviews-link"
        >
          <span>8,000 reviews </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 8 8"
            style={{ marginTop: '0.2rem' }}
          >
            <path
              d="M.536 3.401l-.407.4a.419.419 0 000 .605l3.56 3.471a.446.446 0 00.621 0l3.56-3.469a.419.419 0 000-.605l-.407-.4a.448.448 0 00-.628.007l-2.1 2.15V.429A.433.433 0 004.295 0h-.587a.433.433 0 00-.44.429V5.56l-2.1-2.152a.445.445 0 00-.628-.007z"
              fill="var(--secondary-dark-color)"
            />
          </svg>
        </a>
      </p>
    </div>
  </FullWidthSection>
);

export const MobileStepCarousel = () => {
  return (
    <div className={styles.mobileCarousel}>
      <MobileCarousel
        animation={false}
        cellSpacing={18}
        animateOnLoad={false}
        defaultControlsConfig={{
          pagingDotsContainerClassName: styles.dotsContainer,
          pagingDotsClassName: styles.carouselDots,
        }}
      >
        <div className={styles.mobileStepBlock}>
          <LazyImg
            className={styles.stepImage}
            src="/images/home-page/take-quiz.svg?v2"
            alt="A laptop computer with Sunday quiz opened"
            width="360"
            height="193"
          />
          <h4 className={styles.stepTitle}>Get lawn analysis</h4>
          <p className={styles.stepDescription}>
            Answer a few easy questions about{' '}
            <strong>your lawn and location</strong> to help us create your
            unique plan.
          </p>
        </div>
        <div className={styles.mobileStepBlock}>
          <LazyImg
            className={styles.stepImage}
            src="/images/home-page/sunday-box.svg?v2"
            alt="Sunday shipment and products on a lawn"
            width="360"
            height="193"
          />
          <h4 className={styles.stepTitle}>Open your box</h4>
          <p className={styles.stepDescription}>
            Your first box—including a free soil test—will arrive right when
            your lawn needs it.
          </p>
        </div>
        <div className={styles.mobileStepBlock}>
          <LazyImg
            className={styles.stepImage}
            src="/images/home-page/spray.svg?v2"
            alt="Sunday fertilizer being sprayed from a hose"
            width="360"
            height="193"
          />
          <h4 className={styles.stepTitle}>Spray it on</h4>
          <p className={styles.stepDescription}>
            Attach the custom fertilizer to your hose and spray. Then relax and
            wait for your next box!
          </p>
        </div>
      </MobileCarousel>
    </div>
  );
};

export const DesktopSteps = () => {
  return (
    <div className={styles.stepsContainer}>
      <div className={styles.stepBlock}>
        <LazyImg
          className={styles.stepImage}
          src="/images/home-page/take-quiz.svg?v2"
          alt="A laptop computer with Sunday quiz opened"
          width="360"
          height="193"
        />
        <h4 className={styles.stepTitle}>Get lawn analysis</h4>
        <p className={styles.stepDescription}>
          Answer a few easy questions about{' '}
          <strong>your lawn and location</strong> to help us create your unique
          plan.
        </p>
      </div>
      <div className={styles.stepBlock}>
        <LazyImg
          className={styles.stepImage}
          src="/images/home-page/sunday-box.svg?v2"
          alt="Sunday shipment and products on a lawn"
          width="360"
          height="193"
        />
        <h4 className={styles.stepTitle}>Open your box</h4>
        <p className={styles.stepDescription}>
          Your first box—including a free soil test—will arrive right when your
          lawn needs it.
        </p>
      </div>
      <div className={styles.stepBlock}>
        <LazyImg
          className={styles.stepImage}
          src="/images/home-page/spray.svg?v2"
          alt="Sunday fertilizer being sprayed from a hose"
          width="360"
          height="193"
        />
        <h4 className={styles.stepTitle}>Spray it on</h4>
        <p className={styles.stepDescription}>
          Attach the custom fertilizer to your hose and spray. Then relax and
          wait for your next box!
        </p>
      </div>
    </div>
  );
};

export default SmartLawnPlanSection;
